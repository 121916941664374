import styles from './downloadBox.module.scss';
import getGP from '../../../assets/icons/getOnGooglePlay.svg';
import downloadAS from '../../../assets/icons/donwloadOnAppStore.svg';
import downloads from '../../../data/downloads/downloads.json';
import RenderOn from '../../../Components/RenderOn/RenderOn';
import MintChel from '../../../assets/icons/mintChel.webp';
import MintCamera from '../../../assets/icons/mintCamera.webp';

function DownloadBox(){
    return(
        <div className={styles.positioning_box}>
            <RenderOn platform='desktop'>
                <div className={styles.donwload_box} data-aos="fade-in">
                    <img src={MintChel} alt='Mint Chel Icon' className={styles.mint_icon}/>
                    <a className={styles.mint_button}  href="https://app.pictographs.io/mint/photonauts">MINT</a>
                </div>
            </RenderOn>
            <div className={styles.donwload_box} data-aos="fade-in">
                <p className={styles.download_text}>DOWNLOAD NOW</p>
                <div className={styles.buttons_holder}>
                    <a className={styles.button_link} href={downloads.android}><img src={getGP} alt='Google Play Icon'/></a>
                    <a className={styles.button_link} href={downloads.ios}><img src={downloadAS} alt='App Store Icon'/></a>
                </div>
            </div>
            <RenderOn platform='desktop'>
                <div className={styles.donwload_box} data-aos="fade-in">
                    <img src={MintCamera} alt='Mint Camera Icon' className={styles.mint_icon}/>
                    <a className={styles.mint_button}  href="https://app.pictographs.io/mint/pictographs">MINT</a>
                </div>
            </RenderOn>
            <RenderOn platform='mobile'>
                <div className={styles.donwload_box} data-aos="fade-in">
                    <div className={styles.mint_container}>
                        <img src={MintChel} alt='Mint Chel Icon' className={styles.mint_icon}/>
                        <a className={styles.mint_button} href="https://app.pictographs.io/mint/photonauts">MINT</a>
                    </div>
                    <div className={styles.mint_container}>
                        <img src={MintCamera} alt='Mint Camera Icon' className={styles.mint_icon}/>
                        <a className={styles.mint_button} href="https://app.pictographs.io/mint/pictographs">MINT</a>
                    </div>
                </div>
            </RenderOn>
        </div>
    );
}

export default DownloadBox;
